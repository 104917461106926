












































































// node_modules
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import i18n from '@/i18n';
import { mdiInformationOutline, mdiLogout } from '@mdi/js';

// Components
import UserDetailsCard from '@/components/UserDetailsCard.vue';
import UserForceDisconnect from '@/components/UserForceDisconnect.vue';

// Store's functions
import {
  FETCH_CONNECTED_USERS,
  GET_ALL_CONNECTED_USERS,
  FETCH_AND_RETURN_USER_BY_ID,
  ConnectedUser,
  ListUser,
  CurrentUser,
} from '@/store/users';

export default Vue.extend({
  name: 'UsersSessionView',
  components: {
    UserDetailsCard,
    UserForceDisconnect,
  },
  data: () => {
    return {
      // icons
      mdiLogout,
      mdiInformationOutline,
      usersDetailsToDisplay: [] as ListUser[],

      userToDisconnect: null,
      showCard: 0,
      timeoutId: 0,
      currentUser: null as any | CurrentUser | ListUser | undefined,
      enterDelay: 500,
      leaveDelay: 3000,
    };
  },
  methods: {
    ...mapActions({
      fetchAndReturnUserById: FETCH_AND_RETURN_USER_BY_ID,
    }),
    async fetchAndStoreUserById(userId: number) {
      const userIndex = this.usersDetailsToDisplay.findIndex(
        user => user.id === userId,
      );

      // If the user is already in the usersDetailsToDisplay
      // then delete it
      if (userIndex !== -1) this.usersDetailsToDisplay.splice(userId, 1);
      // this.usersDetailsToDisplay = this.usersDetailsToDisplay.filter(
      //   user => user.id !== userId
      // );

      // Add the user in the usersDetailsToDisplay
      this.usersDetailsToDisplay.push(
        (await this.fetchAndReturnUserById(userId)) as ListUser,
      );
    },
    getUserDetailsToDisplay(userId: number): ListUser | undefined {
      const user = this.usersDetailsToDisplay.find(
        (user: ListUser) => user.id === userId,
      );
      return user;
    },
    updateShowCard(value: number) {
      this.showCard = value;
    },
    updateTimeoutId(value: number) {
      this.timeoutId = value;
    },
    async onmouseenter(func: Function, userId: number) {
      await this.fetchAndStoreUserById(userId);
      this.currentUser = this.getUserDetailsToDisplay(userId);
      func(userId);
    },
  },
  computed: {
    ...mapGetters({
      allConnectedUsers: GET_ALL_CONNECTED_USERS,
    }),
    ...mapActions({
      fetchConnectedUsers: FETCH_CONNECTED_USERS,
    }),
  },
  mounted() {
    this.fetchConnectedUsers;
  },
});
