

































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { debounce } from 'lodash-es';
import { mdiHome } from '@mdi/js';

import { FETCH_SINGLE_ACDB, GET_ACDB } from '../store/acdb';
import { CurrentUser, GET_CURRENT_USER } from '../store/users';

import UserEditCard from '@/components/UserEditCard.vue';

export default Vue.extend({
  name: "UserDetailsCard",
  components: {
    UserEditCard,
  },
  props: [
    'user',
    'showEdit',
    'enterDelay',
    'leaveDelay',
    // shared props btw UserDetails instances
    'timeoutId',
    'showCard',
    'light',
  ],
  data() {
    return {
      mdiHome,
      showEditUserForm: false,
      // timeoutId: 0 as any
    };
  },
  methods: {
    mouseenter(id: number) {
      if (this.timeoutId !== 0) {
        clearInterval(this.timeoutId);
        this.$emit('update:timeoutId', 0);
      }

      debounce(() => {
        this.$emit('update:showCard', id);
      }, this.enterDelay)();
    },
    mouseleave(id: number) {
      const t = setTimeout(() => {
        this.$emit('update:showCard', 0);
      }, this.leaveDelay);

      this.$emit('update:timeoutId', t);
    },
    mouseenterCard() {
      clearInterval(this.timeoutId);
      this.$emit('update:timeoutId');
    },
    mouseleaveCard() {
      const t = setTimeout(() => {
        this.$emit('update:showCard', 0);
        this.$emit('update:timeoutId', 0);
      }, this.leaveDelay);
    },
    canEditUser(user: CurrentUser) {
      // Has the user the permission to update own profile
      return !!this.getAcdb('update', 'ownUser').hasAccess;
    },
    canEditAttribute() {
      // If the current user wants to update it's own information
      // Then check if the attribute to modify is in the user or in the own user's attributes that can be modified
      return this.getAcdb('update', 'ownUser').filteringAttributes;
    },
  },
  async beforeMount() {
    await this.$store.dispatch(FETCH_SINGLE_ACDB, {
      accessType: 'update',
      resourceName: 'ownUser',
    });
  },
  computed: {
    ...mapGetters({
      getAcdb: GET_ACDB,
      currentUser: GET_CURRENT_USER,
    }),
  },
});
