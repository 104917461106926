





































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { IS_LOADING } from '@/store/types';
import { FORCE_LOGOUT } from '@/store/users';

export default Vue.extend({
  props: ['user'],
  methods: {
    async disconnect() {
      await this.$store.dispatch(FORCE_LOGOUT, this.user.userId);
      this.cancel();
    },
    cancel() {
      // Only trigger the event if the application is not loading
      // prevent the dialog to hide when the user click outside of the dialog
      if (!this.isLoading) this.$emit('update:user', null);
    },
  },
  computed: {
    ...mapGetters({
      isLoading: IS_LOADING,
    }),
  },
});
